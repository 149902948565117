import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import i18next from "i18next";
import { v4 } from "uuid";

import { LANG, MIN_BEFORE_EXTEND, LIST_TYPE, URL, ACL_ROLE, COOKIE_PREFIX, APPLICATION_TYPE, CLIENT_ID, MS_ENDPOINT, ENV } from "./Constants";

let _curLangCode = null;
let _localeCode = null;
let _localeCodeForDate = null;

// only this 4 roles should be in this array, the others are special roles
const tenantRoles = [ACL_ROLE.tenantUser, ACL_ROLE.zoneAdmin, ACL_ROLE.tenantAdmin, ACL_ROLE.super];

function _storageSave(key, value) {
  try {
    if (typeof Storage === "function") {
      localStorage.setItem(key, value);
      return true;
    }
  } catch (e) {
    console.error(e);
  }
  return false;
}
function _storageDelete(key) {
  try {
    if (typeof Storage === "function") {
      localStorage.removeItem(key);
      return true;
    }
  } catch (e) {
    console.error(e);
  }
  return false;
}
function _storageGet(key) {
  try {
    if (typeof Storage === "function") {
      return localStorage[key];
    }
  } catch (e) {
    console.error(e);
  }
}

function _getCookie(key) {
  const name = COOKIE_PREFIX + key + "=";
  let cookies = decodeURIComponent(document.cookie).split(";");
  for (let i = 0; i < cookies.length; i++) {
    let c = cookies[i].trimStart();
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
function _deleteCookie(key) {
  document.cookie = COOKIE_PREFIX + key + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.en-trak.com;path=/";
}
function _setCookie(key, value, expiryDays) {
  const d = new Date();
  d.setTime(d.getTime() + (expiryDays || 30) * 24 * 3600 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = COOKIE_PREFIX + key + "=" + value + ";" + expires + ";domain=.en-trak.com;path=/";
}

function _loadLangCode() {
  if (!_curLangCode) {
    _curLangCode = _storageGet("appLanguage");
    if (!_curLangCode) {
      let browserLangCode = null;
      if (window.navigator.language) {
        browserLangCode = window.navigator.language;
      } else if (window.navigator.userLanguage) {
        browserLangCode = window.navigator.userLanguage;
      } else if (window.navigator.browserLanguage) {
        browserLangCode = window.navigator.browserLanguage;
      } else if (window.navigator.systemLanguage) {
        browserLangCode = window.navigator.systemLanguage;
      }
      if (browserLangCode) {
        browserLangCode = browserLangCode.split("-")[0].toLowerCase();
        if (browserLangCode === "cn") {
          _curLangCode = LANG.cn;
        } else if (browserLangCode === "zh") {
          _curLangCode = LANG.zh;
        }
      }
      if (!_curLangCode) {
        _curLangCode = LANG.en;
      }
      _storageSave("appLanguage", _curLangCode);
    }
    _updateLocale(_curLangCode);
  }
  return _curLangCode;
}
function _updateLocale(langCode) {
  if (langCode === LANG.cn) {
    _localeCode = "zh-CN";
    _localeCodeForDate = "zh-CN";
    i18next.changeLanguage("cn");
  } else if (langCode === LANG.zh) {
    _localeCode = "zh-HK";
    _localeCodeForDate = "zh-HK";
    i18next.changeLanguage("zh");
  } else {
    _localeCode = "en-US";
    _localeCodeForDate = "en-UK";
    i18next.changeLanguage("en");
  }
}

function _dateFmt(date, fmt) {
  if (!date || !_localeCodeForDate) {
    return "";
  }

  if (typeof date === "number" || typeof date === "string") {
    date = _getDate(date);
  }

  if (fmt === "long") {
    return date.toLocaleDateString(_localeCodeForDate, {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric"
    });
  }
  if (fmt === "normal") {
    return date.toLocaleDateString(_localeCodeForDate, {
      year: "numeric",
      month: "short",
      day: "numeric"
    });
  } else {
    return date.toLocaleDateString(_localeCodeForDate, {
      month: "short",
      day: "numeric"
    });
  }
}
function _timeFmt(date, fmt) {
  if (!date || !_localeCode) {
    return "";
  }

  if (typeof date === "number" || typeof date === "string") {
    date = _getDate(date);
  }

  if (fmt === "short") {
    return date.toLocaleString(_localeCode, { hour: "numeric" });
  } else {
    return date.toLocaleString(_localeCode, {
      hour: "numeric",
      minute: "2-digit"
    });
  }
}
function _datetimeFmt(date, fmt) {
  if (!date) {
    return "";
  }

  if (typeof date === "number" || typeof date === "string") {
    date = _getDate(date);
  }
  return _dateFmt(date, fmt) + " " + _timeFmt(date);
}
function _getDate(unixTimestamp) {
  return new Date(unixTimestamp * 1000);
}
function _getUnixTimestamp(dateOrTimestamp) {
  if (dateOrTimestamp instanceof Date) {
    return Math.floor(dateOrTimestamp.getTime() / 1000);
  } else {
    return dateOrTimestamp;
  }
}
function _getMinuteDiff(date1, date2, noRounding) {
  if (date1 == null) {
    console.warn("getMinuteDiff date1:", date1);
    return null;
  }

  date1 = _getUnixTimestamp(date1);
  if (!date2) {
    date2 = _getUnixTimestamp(new Date());
  } else {
    date2 = _getUnixTimestamp(date2);
  }

  if (noRounding) {
    return (date1 - date2) / 60;
  } else {
    return Math.round((date1 - date2) / 60);
  }
}

function _arrayToMap(arr, key, map) {
  if (!key) key = "id";
  if (!map) map = {};
  if (arr) {
    for (var i = 0; i < arr.length; i++) {
      map[arr[i][key]] = arr[i];
    }
  }

  return map;
}

function getDisplayNameObj(user) {
  var fName = (user?.firstName || "").trim();
  var lName = (user?.lastName || "").trim();

  if (fName || lName) {
    return {
      fName: fName,
      lName: lName
    };
  } else if (user?.email) {
    return {
      fName: "",
      lName: user.email.split("@")[0]
    };
  } else {
    return null;
  }
}

function _isCheckedIn(ws) {
  return ws ? ws.status : null;
}

function _needOnByTimer(node) {
  return !_isCheckedIn(node) && !node.schedule.withIn;
}

function _isWorkstation(node) {
  return node.__typename === "Workstation";
}

function _hasEnvironmentalReadings(device) {
  let type = device?.applicationType;
  return type === APPLICATION_TYPE.aircon || type === APPLICATION_TYPE.iaqSensor || type === APPLICATION_TYPE.lightSensor || type === APPLICATION_TYPE.thermometer;
}

function _hasEnergyLog(device) {
  return device?.applicationType === APPLICATION_TYPE.energyMeter;
}

function _isAclRole(user, role) {
  if (user?.aclRoles) {
    for (let r = 0; r < user.aclRoles.length; r++) {
      if (role === user.aclRoles[r]) {
        return true;
      }
    }
  }
  return false;
}

function _getAclRoleLv(role) {
  return tenantRoles.indexOf(role);
}

function _getUserAclRoleLv(user) {
  if (user?.aclRoles) {
    for (let i = tenantRoles.length - 1; i >= 0; i--) {
      if (user.aclRoles.indexOf(tenantRoles[i]) != -1) {
        return i;
      }
    }
  }
  return -1;
}

function _hasMinAclRole(user, minRole) {
  if (!user?.aclRoles || !minRole) {
    return false;
  }
  const requiredLv = _getAclRoleLv(minRole);
  const userLv = _getUserAclRoleLv(user);
  if (requiredLv == -1 || userLv == -1) {
    return false;
  } else {
    return userLv >= requiredLv;
  }
}

function _getDomain() {
  return window.location.origin + "/";
}

export const Utils = {
  storageSave: _storageSave,
  storageGet: _storageGet,
  storageDelete: _storageDelete,

  getCookie: _getCookie,
  setCookie: _setCookie,
  deleteCookie: _deleteCookie,

  loadLangCode: _loadLangCode,
  saveLangCode: function (langCode) {
    if (langCode !== _curLangCode) {
      if (Object.values(LANG).indexOf(langCode) == -1) {
        langCode = _loadLangCode();
      }
      _curLangCode = langCode;
      _updateLocale(langCode);
      _storageSave("appLanguage", langCode);
    }
  },

  numFmt: function (num, decimal) {
    if (typeof num === "number") {
      if (typeof decimal === "number") {
        return num.toFixed(decimal);
      } else {
        let n = Math.abs(num);
        if (n < 1) {
          return num.toFixed(2);
        } else if (n < 10) {
          return num.toFixed(1);
        } else {
          return num.toFixed();
        }
      }
    } else {
      return "-";
    }
  },

  dateFmt: _dateFmt,
  timeFmt: _timeFmt,
  datetimeFmt: _datetimeFmt,

  addMinute: function (date, minute) {
    if (date) {
      if (typeof date === "object") {
        date = _getUnixTimestamp(date);
      }
      return date + minute * 60;
    }
  },
  getDate: _getDate,
  getUnixTimestamp: _getUnixTimestamp,
  getMinuteDiff: _getMinuteDiff,
  getCurrentMinuteUTC: function (offset) {
    let d = new Date();
    d.setHours(d.getHours(), d.getMinutes(), 0, 0);
    d = _getUnixTimestamp(d);
    return offset ? d + offset * 60 : d;
  },
  getCurrentHourUTC: function (offset) {
    let d = new Date();
    d.setHours(d.getHours(), 0, 0, 0);
    d = _getUnixTimestamp(d);
    return offset ? d + offset * 3600 : d;
  },
  getCurrentDateUTC: function (offset) {
    let d = new Date();
    d.setHours(0, 0, 0, 0);
    d = _getUnixTimestamp(d);
    return offset ? d + offset * 86400 : d;
  },

  arrayToMap: _arrayToMap,
  replaceArrItem: function (arr, items, addIfNotFound, keyName) {
    //default by id
    if (!Array.isArray(items)) items = [items];
    if (!keyName) keyName = "id";

    for (let i = 0; i < items.length; i++) {
      let j = 0;
      for (; j < arr.length; j++) {
        if (arr[j][keyName] === items[i][keyName]) {
          arr.splice(j, 1, items[i]);
          break;
        }
      }
      if (addIfNotFound && j == arr.length) arr.push(items[i]);
    }
  },
  deleteArrItem: function (arr, itemOrId, keyName) {
    //default by id
    let fieldName = keyName ? keyName : "id";
    if (itemOrId != null && typeof itemOrId === "object") itemOrId = itemOrId[fieldName];

    for (let j = 0; j < arr.length; j++) {
      if (arr[j][fieldName] == itemOrId) {
        return arr.splice(j, 1)[0];
      }
    }
  },
  getArrItem: function (arr, val, keyName) {
    //default by id
    if (!keyName) keyName = "id";
    for (let j = 0; j < arr.length; j++) {
      if (arr[j][keyName] == val) return arr[j];
    }
    return null;
  },
  getScheduleSorter: function () {
    const dow = {
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6,
      SUNDAY: 7
    };
    return function (a, b) {
      if (dow[a.weekday] > dow[b.weekday]) {
        return 1;
      } else if (dow[a.weekday] < dow[b.weekday]) {
        return -1;
      } else if (a.start.hour > b.start.hour) {
        return 1;
      } else if (a.start.hour < b.start.hour) {
        return -1;
      } else if (a.start.minute > b.start.minute) {
        return 1;
      } else if (a.start.minute < b.start.minute) {
        return -1;
      } else {
        return 0;
      }
    };
  },
  getSorter: function (field1, isNum1, field2, isNum2) {
    //default field: "name"
    if (!field1) {
      field1 = "name";
    }
    if (field2) {
      return function (a, b) {
        let field1Res = isNum1 ? a[field1] - b[field1] : (a[field1] || "").toLowerCase().localeCompare((b[field1] || "").toLowerCase());
        if (field1Res) {
          return field1Res;
        } else {
          if (isNum2) {
            return a[field2] - b[field2];
          } else {
            return (a[field2] || "").toLowerCase().localeCompare((b[field2] || "").toLowerCase());
          }
        }
      };
    } else {
      if (isNum1) {
        return function (a, b) {
          return a[field1] - b[field1];
        };
      } else {
        return function (a, b) {
          return (a[field1] || "").toLowerCase().localeCompare((b[field1] || "").toLowerCase());
        };
      }
    }
  },

  getUserDisplayName: function (t, user) {
    let nameObj = getDisplayNameObj(user);
    if (nameObj) {
      return t("lbl.fullName", nameObj).trim();
    } else {
      return "";
    }
  },

  getNodeCenter: function (node) {
    if (node && node.locationPolygon) {
      let x = 0;
      let y = 0;
      let strArr = node.locationPolygon.trim().replace(/,\s+/g, ",").replace(/\s+,/g, ",").split(/\s+/);
      for (let i = 0; i < strArr.length; i++) {
        let tmp = strArr[i].split(",");
        x += parseFloat(tmp[0], 10);
        y += parseFloat(tmp[1], 10);
      }
      x = x / strArr.length;
      y = y / strArr.length;
      return { x, y };
    }
  },

  getEnabledCards: function (user) {
    let res = {
      hasSeeData: false,
      hasGreenScoreMore: false,
      hasRating: false,
      hasAircon: false,
      hasLighting: false,
      hasIaq: false,
      hasEnergy: false
    };
    if (user) {
      const isSuper = _hasMinAclRole(user, ACL_ROLE.super) && "1" == _storageGet("showAllCards");
      res.hasRating = isSuper || user.tenant.widget.greenScoreRatingEnable;
      res.hasAircon = isSuper || user.tenant.widget.airconEnable;
      res.hasLighting = isSuper || user.tenant.widget.lightingEnable;
      res.hasIaq = isSuper || user.tenant.widget.indoorAirQualityEnable;
      res.hasEnergy = isSuper || user.tenant.widget.energyConsumptionEnable;
      res.hasSeeData = res.hasAircon || res.hasLighting || res.hasIaq || res.hasEnergy;
    }
    res.hasGreenScoreMore = res.hasAircon;
    return res;
  },

  handleToggleNode: function (e, node, userRes, modal, toggleMethods, errHandler) {
    const isRm = !_isWorkstation(node);
    const isTurnOn = e.target.checked;
    if (isTurnOn) {
      if (_needOnByTimer(node)) {
        return modal.asyncOpen({ id: "TimerModal", node, isRm, isExtend: false, userRes });
      } else {
        return (isRm ? toggleMethods.turnOnRm : toggleMethods.turnOnWs)({ variables: { id: node.id } }).then(() => {
          modal.turnOnMsg();
          return true;
        }, errHandler);
      }
    } else {
      return (isRm ? toggleMethods.turnOffRm : toggleMethods.turnOffWs)({ variables: { id: node.id } }).then(() => {
        modal.turnOffMsg();
        return true;
      }, errHandler);
    }
  },

  isWorkstation: _isWorkstation,

  isDimmable: function (device) {
    return device?.dimmable && device?.applicationType === APPLICATION_TYPE.light;
  },

  hasReadings: function (device) {
    return _hasEnergyLog(device) || _hasEnvironmentalReadings(device);
  },
  hasEnvironmentalReadings: _hasEnvironmentalReadings,
  hasEnergyLog: _hasEnergyLog,

  getListType: function (node) {
    if (_isWorkstation(node)) {
      return node.isHotDesk ? LIST_TYPE.hotdesk : LIST_TYPE.fixdesk;
    } else {
      return LIST_TYPE.room;
    }
  },

  isCheckedIn: _isCheckedIn,

  needOnByTimer: _needOnByTimer,

  getExtendInfo: function (node) {
    let res = {
      minute: "-",
      needShowExtend: false
    };
    if (_isCheckedIn(node) && node.schedule.nextOffAt) {
      let diff = _getMinuteDiff(node.schedule.nextOffAt);
      if (diff != null) {
        res.minute = Math.ceil(diff);
        res.needShowExtend = diff < MIN_BEFORE_EXTEND;
      }
    }
    return res;
  },
  needShowTemperature: function (node) {
    return _isCheckedIn(node) && node.count.aircon > 0;
  },
  needShowDimming: function (node) {
    return _isCheckedIn(node) && node.count.smartlight > 0;
  },
  needShowScene: function (node) {
    return _isCheckedIn(node) && node.scenes.length > 0;
  },

  getNodeStatusMsg: function (t, node, timeOnly) {
    if (_isCheckedIn(node)) {
      if (node.schedule.nextOffAt) {
        let date = _getDate(node.schedule.nextOffAt);
        let dayEnd = new Date();
        dayEnd.setHours(23, 59, 59, 999);
        let timeStr = dayEnd.getTime() < date.getTime() ? _datetimeFmt(date) : _timeFmt(date);
        return timeOnly ? timeStr : t("lbl.nodeOnUntil", { time: timeStr });
      } else {
        return timeOnly ? "-" : t("lbl.nodeOn");
      }
    } else {
      if (node.schedule.nextOnAt) {
        let date = _getDate(node.schedule.nextOnAt);
        let dayEnd = new Date();
        dayEnd.setHours(23, 59, 59, 999);
        let timeStr = dayEnd.getTime() < date.getTime() ? _datetimeFmt(date) : _timeFmt(date);
        return timeOnly ? timeStr : t("lbl.nodeNextOn", { time: timeStr });
      } else {
        return timeOnly ? "-" : t("lbl.nodeOff");
      }
    }
  },

  hasMinAclRole: _hasMinAclRole,

  getAclRoleLv: _getAclRoleLv,

  getUserAclRoleLv: _getUserAclRoleLv,

  isAclRole: _isAclRole,

  isEmail: function (email) {
    if (email?.trim()) {
      //let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    return false;
  },

  post: function (endpoint, dataObj) {
    return fetch(URL + endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataObj),
      credentials: "include"
    });
  },
  get: function (endpoint, dataObj) {
    return fetch(URL + endpoint, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataObj),
      credentials: "include"
    });
  },
  formPost: function (endpoint, dataObj, needToken) {
    const arr = Object.keys(dataObj || {});
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
    if (needToken) {
      headers.append("x-request-id", v4());
      headers.append("Authorization", "Bearer " + (ENV === "local" ? _storageGet("et_it") : _getCookie("et_it")));
      headers.append("Authorization-Method", ENV === "local" ? _storageGet("et_method") : _getCookie("et_method"));
    }
    return fetch(URL + endpoint, {
      method: "POST",
      headers,
      credentials: "include",
      body: arr.map(k => `${k}=${encodeURIComponent(dataObj[k])}`).join("&")
    });
  },
  parseJson: function (response) {
    if (response.ok) {
      return response.json();
    } else {
      console.log("fetch not ok");
      throw response;
    }
  },
  parseBlob: function (response) {
    if (response.ok) {
      return response.blob();
    } else {
      console.log("fetch not ok");
      throw response;
    }
  },

  getDomain: _getDomain,

  // noPrompt = true for slient login
  msSignIn: function (noPrompt) {
    let verifier = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (let i = 0; i < 80; i++) {
      verifier += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    const challenge = sha256(verifier).toString(Base64).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");

    if (Utils.storageSave("codeVerifier", verifier)) {
      window.location.href =
        MS_ENDPOINT +
        "oauth2/v2.0/authorize?client_id=" +
        CLIENT_ID +
        "&response_type=code&response_mode=query&prompt=" +
        (noPrompt ? "none" : "select_account") +
        "&redirect_uri=" +
        encodeURIComponent(_getDomain()) +
        "&code_challenge=" +
        challenge +
        "&code_challenge_method=S256" +
        "&scope=openid%20email%20profile%20offline_access" +
        "&state="; // state param is optional, can pass any value to the redirect_uri
    }
  },

  refreshMsToken: function (refreshToken, success, error) {
    let request = new XMLHttpRequest();
    request.open("POST", MS_ENDPOINT + "oauth2/v2.0/token", true);
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    request.onload = function () {
      try {
        if (request.status == 200) {
          success(JSON.parse(request.response));
        } else {
          error(JSON.parse(request.response));
        }
      } catch (e) {
        error({ error: e });
      }
    };
    request.onerror = function () {
      error({ error: "unexpected error" });
    };
    var body = "grant_type=refresh_token&refresh_token=" + refreshToken + "&client_id=" + CLIENT_ID + "&scope=openid email profile offline_access";
    request.send(body);
  },

  clearSignInInfo: function () {
    if (ENV === "local") {
      _storageDelete("et_method");
      _storageDelete("et_it");
      _storageDelete("et_rt");
      _storageDelete("et_at");
    } else {
      _deleteCookie("et_method");
      _deleteCookie("et_it");
      _deleteCookie("et_rt");
      _deleteCookie("et_at");
    }
  },
  saveSignInInfo: function (method, idToken, refreshToken, accessToken) {
    if (ENV === "local") {
      _storageSave("et_method", method);
      _storageSave("et_it", idToken);
      if (refreshToken) {
        _storageSave("et_rt", refreshToken);
      }
      if (accessToken) {
        _storageSave("et_at", accessToken);
      } else {
        _storageDelete("et_at");
      }
    } else {
      _setCookie("et_method", method);
      _setCookie("et_it", idToken);
      _setCookie("et_at", accessToken);
      if (refreshToken) {
        _setCookie("et_rt", refreshToken);
      }
      if (accessToken) {
        _setCookie("et_at", accessToken);
      } else {
        _deleteCookie("et_at");
      }
    }
  }
};

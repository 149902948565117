import { COLOR } from "../../../Constants";
import { PAGE_PERIOD_LENGEND_TEXT, EXPECTION_VALUE, WIDGET_UNIT_BY_SOURCE_TYPE, UNIT_TO_COST_VALUE, UNIT_TO_SQUARE, UNIT_COST_TO_YAXIS_VALUE } from "../../insightsPage/common";
import { WIDGET_CHART_COMMON_STYLE } from "../../insightsPage/commonOption";
import moment from "moment";
import React from "react";
export const PAGE_LAYOUT_PARAMETER = {
  WIDGET_ENFORCED_MINSIZE: {
    ScoreCardWidget: {
      minW: 2,
      minH: 2
    },
    BarChartWaterBreakDownWidget: {
      minW: 2,
      minH: 2
    },
    BarChartEnergyBreakDownWidget: {
      minW: 2,
      minH: 2
    },
    BarChartWithMeterConsumptionPercentDifferWidget: {
      minW: 1,
      minH: 2
    },
    BarChartWithConsumptionRankingWidget: {
      minW: 2,
      minH: 2
    },
    PieChartConsumptionWidget: {
      minW: 2,
      minH: 3
    },
    LineChartWidget: {
      minW: 2,
      minH: 3
    },
    BarChartWithMeterUsageTypeWidget: {
      minW: 2,
      minH: 3
    },
    HeatMapWidget: {
      minW: 2,
      minH: 3
    },
    BarAndLineChartWithComparisonWidget: {
      minW: 2,
      minH: 3
    },
    BarChartWithMeterConsumptionCompareWidget: {
      minW: 2,
      minH: 3
    },
    BarChartWithBDSTWidget: {
      minW: 2,
      minH: 3
    },
    TemperatureWidget: {
      minW: 2,
      minH: 3
    },
    WeatherWidget: {
      minW: 2,
      minH: 4
    },
    ACRequestSummaryWidget: {
      minW: 2,
      minH: 3
    },
    ACRequestCompareWidget: {
      minW: 2,
      minH: 2
    },
    BarAndLineChartWithLightingOperateHourWidget: {
      minW: 2,
      minH: 3
    },
    BarAndLineChartWithACTemperatureWidget: {
      minW: 2,
      minH: 3
    },
    BarChartHorizontalWithStackedWidget: {
      minW: 2,
      minH: 2
    }
  },
  LAYOUT_RESPONSIVE_GRID: {
    margin: [25, 20],
    containerPadding: [20, 20],
    rowHeight: 80,
    cols: { lg: 6, md: 4, sm: 4, xs: 1, xxs: 1 },
    breakpoints: { lg: 1440, md: 996, sm: 768, xs: 480 }
  }
};
export const PAGE_LAYOUT_WIDGET_PARAMETER = {
  WIDGET_SOURCE_TYPE: {
    WET_ELECTRICITY: "WET_ELECTRICITY",
    WET_WATER: "WET_WATER",
    WET_TEMPERATURE: "WET_TEMPERATURE",
    WET_UNKNOW: "WET_UNKNOW"
  }
};
const WIDGET_CHART_STYLE = {
  _axis: {
    fontSize: "0.78rem"
  },
  _axisLineStyle: {
    color: "#ccc", // 刻度标线的颜色
    width: 0.5, // 刻度标线的宽度
    type: "solid" // 刻度标线的类型（实线、虚线等）
  }
};
const WIDGET_CHART_ACTION = {
  _unitFormatter: function (params, type, unit) {
    let value = UNIT_TO_COST_VALUE(unit, params.value, false);
    unit = WIDGET_UNIT_BY_SOURCE_TYPE[unit] + UNIT_TO_SQUARE(unit);
    return `{name|${value}}\n${unit}`;
  },
  _barInnerRich: {
    name: {
      fontSize: "0.92rem",
      fontWeight: "normal",
      fontFamily: "Inter",
      padding: [0, 0, 4, 0]
    },
    unit: {
      fontSize: "0.92rem",
      fontWeight: "normal",
      fontFamily: "Inter"
    },
    num: {
      fontSize: 8
    }
  }
};
export const ToDecimal = val => {
  if (val >= 10) {
    return Math.round(val);
  } else if (val >= 1 && val < 10) {
    return Math.round(val * 10) / 10;
  } else if (val >= 0 && val < 1) {
    return Math.round(val * 100) / 100;
  } else {
    return Math.round(val * 10) / 10;
  }
};
export const ToRoundDecimal = val => {
  if (val >= 10) {
    return Math.round(val);
  } else if (val >= 1 && val < 10) {
    return Math.round(val * 10) / 10;
  } else if (val >= 0 && val < 1) {
    return Math.round(val * 100) / 100;
  } else {
    return Math.round(val * 10) / 10;
  }
};
export const ToRoundDecimalTemperature = val => {
  if (val >= 1) {
    return Math.round(val * 10) / 10;
  } else if (val > 0 && val < 1) {
    return Math.round(val * 100) / 100;
  } else {
    return Math.round(val * 10) / 10;
  }
};

export const widgetChartResize = chartRef => {
  if (chartRef?.current) {
    chartRef.current.resize();
  }
};
export const BarChartWithMeterMulitColorOption = (CATEGORY, DATA, LENGEND_DATA, type, width, widget) => {
  const lessDataColor = ["#6ED2C8", "#78AEDC"];
  const barColorDarkArr = ["#D75455", "#E97D7D", "#5CB85C", "#79CC79", "#BD4B85", "#C96BA1", "#E89F3E", "#F5B75F"];
  let unit = WIDGET_UNIT_BY_SOURCE_TYPE[widget.unit];
  const series = [],
    legendData = [];
  if (!DATA?.length) {
    return {};
  }
  let MAX = 1;
  DATA.map((item, index) => {
    if (MAX < Math.max(...item.value)) {
      MAX = Math.max(...item.value);
    }
  });
  let tempMax = { max: 0 };
  DATA?.map((item, index) => {
    let max = Math.max(...item.value);
    if (tempMax.max < max) {
      tempMax.max = max;
    }
    let currentColor = [];
    if (DATA.length > 2) {
      currentColor = barColorDarkArr[index % barColorDarkArr.length];
    } else {
      if (index % 2 == 0) {
        currentColor = lessDataColor[0];
      } else {
        currentColor = lessDataColor[1];
      }
    }
    series.push({
      name: item?.name,
      type: "bar",
      barCategoryGap: "0%", // 设置类目轴上的柱子之间的间隔为0
      barWidth: (1 / DATA.length) * 100 - 10 + "%", // 可以根据需要设置柱子宽度
      barGap: "0.1", // 设置柱子之间的间隔为0
      tooltip: {
        valueFormatter: function (value) {
          return ToDecimal(value) + item?.unit;
        }
      },
      itemStyle: {
        normal: {
          color: function (params) {
            return currentColor;
          },
          borderRadius: [3, 3, 0, 0]
        }
      },
      label: {
        show: false,
        position: "inside", // 自定义标签的位置，[x, y]
        distance: 10,
        formatter: function (params) {},
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
        }
      },

      data: item?.value
    });
    legendData.push({
      name: item?.name,
      icon: "rect",
      textStyle: {
        color: currentColor, // 设置图例1的文字颜色为蓝色
        fontSize: "0.78rem"
      },
      itemStyle: { color: currentColor }
    });
  });
  return {
    tooltip: {
      trigger: "axis",
      confine: true,
      z: 9999,
      formatter: function (params) {
        let html = "",
          name = "";
        params.forEach(item => {
          name = item.name + "<br/>";
          let result = UNIT_TO_COST_VALUE(widget?.unit, item.value, true);
          html += `${item.marker}${item?.seriesName}：<span class="bold">${result}</span><br/>`;
        });
        return name + html;
      }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._gridIncludeLegend,
      containLabel: true,
      splitLine: {
        show: true,
        lineStyle: {
          type: "solid"
        }
      }
    },
    legend: {
      data: legendData,
      ...WIDGET_CHART_COMMON_STYLE["_legend"],
      top: 5,
      padding: [10, 0, 0, 0],
      formatter: function (name) {
        return "{a|" + name + "}";
      },
      textStyle: {
        ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle,
        letterSpacing: 10,
        rich: {
          a: {
            width: "auto", // 设置宽度自适应
            fontSize: "12px"
          }
        }
      }
    },
    xAxis: [
      {
        type: "category",
        data: CATEGORY,
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: COLOR.mainSubLight, // 标线的颜色
            width: 1, // 标线的宽度
            type: "solid" // 标线的类型（实线、虚线等）
          }
        },
        axisLabel: {
          show: true,
          interval: 0,
          textStyle: {
            ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
          },
          formatter: function (value) {
            if (width <= 2) {
              let str = "";
              let strArr = value.split(" ");
              for (let item in strArr) {
                str += strArr[item] + "\n";
              }
              return value;
            } else {
              return value;
            }
          },
          tooltip: {
            show: true
          }
        }
      }
    ],
    yAxis: [
      {
        type: "value",
        name: unit + UNIT_TO_SQUARE(widget?.unit),
        min: 0,
        MAX: parseInt(MAX),
        nameTextStyle: { color: COLOR.mainSubLight, fontSize: "0.78rem", align: widget?.unit.indexOf("EUI") != -1 ? "center" : "right" },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false,
          lineStyle: {
            color: COLOR.mainSubLight
          }
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: COLOR.mainSubLight, // 标线的颜色
            width: 1, // 标线的宽度
            type: "solid" // 标线的类型（实线、虚线等）
          }
        },
        axisLabel: {
          show: true,
          textStyle: {
            ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
          },
          formatter: function (value) {
            return UNIT_COST_TO_YAXIS_VALUE(widget.unit, value);
          }
        }
      }
    ],
    series: [...series]
  };
};
export const BarChartWithMeterConsumptionCompareOption = (category, list, type, series, xAxis, widget) => {
  const _DATA = { ...list[0] } || [];
  if (!_DATA?.value?.length) {
    return {};
  }
  let MAX = 1,
    compareMAX = 0;
  let tempSeries = [];
  _DATA.value = _DATA.value.map((item, index) => {
    MAX += item;
    if (item > compareMAX) {
      compareMAX = item;
    }
    let newItem = {
      value: item,
      color: WIDGET_CHART_COMMON_STYLE._barStyle.color[index]
    };
    return newItem;
  });

  return {
    tooltip: {
      formatter: function (params) {
        return `${params.name}<br/>${params.marker}<span class="bold">${UNIT_TO_COST_VALUE(widget.unit, params.value, true)}</span>`;
      }
    },
    xAxis: {
      type: "category",
      data: category,
      axisTick: { show: false },
      axisLine: {
        show: true,
        lineStyle: {
          color: COLOR.main
        }
      },
      axisLabel: {
        show: true,
        interval: 0,
        formatter: function (value) {
          return value;
        },
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
        }
      }
    },
    yAxis: {
      type: "value",
      scale: true, // 自适应设置
      min: 0,
      axisTick: { show: false },
      axisLabel: { show: false },
      splitLine: { show: false }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._grid
    },
    series: [
      {
        data: _DATA.value,
        type: "bar",
        barCategoryGap: 50, // 移除同一类目下的间隙
        itemStyle: {
          color: function (params) {
            return params.data.color;
          }
        },
        label: {
          show: true,
          position: "inside",
          fontsize: "0.92rem",
          fontFamily: "Inter",
          fontWeight: "normal",
          color: WIDGET_CHART_COMMON_STYLE._dashboard_textStyle.greyColor,
          textStyle: { align: "center", fontWeight: "normal", fontFamily: "Inter" },
          formatter: function (params) {
            if (params.value / compareMAX < 0.25 || params.value == 0) {
              return "";
            }
            return WIDGET_CHART_ACTION._unitFormatter(params, widget.source, widget.unit);
          },
          rich: { ...WIDGET_CHART_ACTION._barInnerRich }
        }
      }
    ]
    // barCategoryGap:0 // 移除同一类目下的间隙
  };
};
export const LineChartWidgetOption = (category, list, type, width, widget) => {
  if (!category.length || !list.length) {
    return {};
  }
  let unit = WIDGET_UNIT_BY_SOURCE_TYPE[widget.unit];
  const color = WIDGET_CHART_COMMON_STYLE._barStyle.group;
  let seriesData = [],
    legendData = [],
    tempLengendData = [];
  let tempMax = { max: 0 };
  list?.map((item, index) => {
    let max = Math.max(...item.data);
    if (tempMax.max < max) {
      tempMax.max = max;
    }
    item.data = item?.data?.map(function (item) {
      return item === EXPECTION_VALUE ? null : item;
    });
    seriesData.push({
      data: item?.data,
      name: item?.name,
      lineStyle: {
        color: color[index], // 标线的颜色
        width: 2, // 标线的宽度
        type: "solid" // 标线的类型（实线、虚线等）
      },
      ...WIDGET_CHART_COMMON_STYLE._seriesSymbolStyle,
      itemStyle: {
        color: color[index],
        borderColor: COLOR.mainSubLight, // 设置边框颜色
        borderWidth: 2.5 // 设置边框宽度
      }
    });
    legendData.push({
      name: item?.name,
      icon: "rect",
      textStyle: {
        color: color[index], // 设置图例1的文字颜色为蓝色
        fontSize: "1rem"
      },
      itemStyle: { color: color[index], borderColor: "transparent", fontSize: "12px", fontFamily: "Inter" }
    });
    tempLengendData.push(item?.name);
  });
  return {
    legend: {
      show: true,
      data: [...legendData],
      ...WIDGET_CHART_COMMON_STYLE["_legend"],
      top: 5,
      itemGap: 2,
      formatter: function (name) {
        return "{a|" + name + "}";
      },
      textStyle: {
        ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle,
        letterSpacing: 10,
        rich: {
          a: {
            width: "auto" // 设置宽度自适应
          }
        }
      }
    },
    tooltip: {
      formatter: function (params) {
        let val = 0;
        val = UNIT_TO_COST_VALUE(widget?.unit, params.value, true);
        return `${list[params.seriesIndex]?.name || ""}<br/>${params.marker}${getTimeFormatByPelletOrViewby(widget, params.name, true)} ：${val}`;
      }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._gridIncludeLegend,
      splitLine: {
        show: true,
        lineStyle: {
          type: WIDGET_CHART_STYLE._axisLineStyle.type
        }
      }
    },
    xAxis: {
      type: "category",
      data: category,
      splitLine: {
        show: false,
        lineStyle: {
          color: WIDGET_CHART_STYLE._axisLineStyle.color // 设置分隔线的颜色
        }
      },
      axisTick: {
        show: false, // 显示 X 轴的刻度标线
        alignWithLabel: true, // 刻度标线与刻度标签对齐
        lineStyle: {
          ...WIDGET_CHART_STYLE._axisLineStyle
        }
      },
      axisLine: {
        lineStyle: {
          ...WIDGET_CHART_STYLE._axisLineStyle,
          color: COLOR.mainSubLight // 标线的颜色
        }
      },
      axisLabel: {
        show: true,
        interval: category.length >= 30 ? 2 : category.length >= 14 ? 1 : 0,
        formatter: function (value) {
          return getTimeFormatByPelletOrViewby(widget, value);
        },
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
        }
      }
    },

    yAxis: {
      type: "value",
      name: unit + UNIT_TO_SQUARE(widget?.unit),
      nameTextStyle: { color: COLOR.mainSubLight, fontSize: "0.78rem", align: widget?.unit.indexOf("EUI") != -1 ? "center" : "right" },
      splitLine: {
        show: true,
        lineStyle: {
          width: 0.5, // 刻度标线的宽度
          color: "white" // 设置分隔线的颜色
        }
      },
      axisLine: {
        show: true, // 显示 Y 轴的标线
        lineStyle: {
          ...WIDGET_CHART_STYLE._axisLineStyle,
          color: COLOR.mainSubLight // 标线的颜色
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
        },
        formatter: function (value) {
          return UNIT_COST_TO_YAXIS_VALUE(widget.unit, value);
        }
      }
    },
    series: [...seriesData]
  };
};
//PieChartConsumptionWidget - option
export const PieChartWidgetOption = (listObj, widget) => {
  if (!listObj?.list?.length) {
    return {};
  }
  return {
    grid: {
      // left: "-5%"
      ...WIDGET_CHART_COMMON_STYLE._gridIncludeLegend
    },
    title: {
      text: UNIT_TO_COST_VALUE(widget.unit, listObj?.total || 0, true),
      textStyle: {
        color: COLOR.mainSubLight,
        fontSize: "0.92rem",
        fontFamily: "Inter"
      },
      subtext: "Used",
      subtextStyle: {
        color: COLOR.mainSubLight,
        fontSize: "1.07rem",
        fontFamily: "Inter"
      },
      itemGap: 5,
      top: "center",
      left: "center"
    },
    tooltip: {
      formatter: function (params) {
        const {
          data: { originalValue }
        } = params;
        return `${params.marker}${params.name}：<div class="bold">${params.value}%(${UNIT_TO_COST_VALUE(widget.unit, originalValue, true)})</div>`;
      }
    },
    series: [
      {
        type: "pie",
        radius: ["45%", "60%"],
        center: ["50%", "50%"],
        clockwise: true,
        avoidLabelOverlap: true,
        hoverAnimation: false,
        itemStyle: {
          normal: {
            color: function (params) {
              const group = WIDGET_CHART_COMMON_STYLE._barStyle.group;
              return group[params.dataIndex % group.length];
            }
          }
        },
        label: {
          show: true,
          position: "outer",
          formatter: "{icon|''}{name|{b}}\n{value|{c}%}",
          rich: {
            icon: {
              width: 10,
              height: 10,
              backgroundColor: "inherit",
              color: "inherit",
              fontFamily: "Inter"
            },
            name: {
              fontSize: 11,
              fontWeight: "normal",
              padding: [0, 10, 0, 4],
              color: COLOR.mainSubLight
            },
            value: {
              fontSize: "0.78rem",
              fontWeight: "normal",
              padding: [2, 0, 0, 15],
              color: COLOR.mainSubLight,
              fontFamily: "Inter",
              align: "left"
            }
          }
        },
        labelLine: {
          normal: {
            length: 5,
            length2: 5,
            smooth: 0, // 使标签线直接指向饼图中心
            lineStyle: {
              width: 0,
              color: "#CDCDCD"
            }
          }
        },
        data: listObj?.list || []
      }
    ]
  };
};
export const BarAndLineChartWithACTemperatureWidgetOption = (curChart, prevChart) => {
  let category = [],
    curBarChartData = [],
    prevLineChart = [];

  curChart?.averageDay?.map(item => {
    category.push(moment(item.date).format("D/MM"));
    curBarChartData.push(item.value);
  });

  prevChart?.averageDay?.map(item => {
    prevLineChart.push(item.value);
  });
  return {
    legend: {
      show: true,
      data: [
        { name: "Last week", icon: "rect", itemStyle: { color: "#6ED2C8", borderColor: "transparent", fontSize: "0.78rem", fontFamily: "Inter" } },
        { name: "Previous week", icon: "rect", textStyle: { color: "#FFA500" }, itemStyle: { borderColor: "transparent" } }
      ],
      ...WIDGET_CHART_COMMON_STYLE["_legend"],
      top: 5,
      itemGap: 5,
      // padding: [12, 5, 0, 0],
      formatter: function (name) {
        return "{a|" + name + "}";
      },
      textStyle: {
        ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle,
        letterSpacing: 10,
        rich: {
          a: {
            width: "auto" // 设置宽度自适应
          }
        }
      }
    },
    tooltip: {
      show: true,
      trigger: "axis",
      formatter: function (params) {
        let html = "";
        params.forEach(item => {
          html += `${item.marker}${item.seriesName}:<span class="bold">${ToDecimal(item.value)}°C</span><br/>`;
        });
        return html;
      }
    },
    xAxis: {
      type: "category",
      data: category,
      splitLine: {
        show: false
      },
      axisTick: {
        show: false // 显示 X 轴的刻度标线
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: COLOR.main // 标线的颜色
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
        }
      }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._gridIncludeLegend,
      containLabel: true,
      splitLine: {
        show: true,
        lineStyle: {
          type: WIDGET_CHART_STYLE._axisLineStyle.type
        }
      }
    },
    yAxis: {
      type: "value",
      name: "Deg°C",
      nameTextStyle: { color: COLOR.mainSubLight, fontSize: "0.78rem", align: "center" },
      splitLine: {
        show: false,
        lineStyle: {
          width: 0.5, // 刻度标线的宽度
          color: "white" // 设置分隔线的颜色
        }
      },
      axisLine: {
        show: true, // 显示 Y 轴的标线
        lineStyle: {
          ...WIDGET_CHART_STYLE._axisLineStyle,
          color: COLOR.mainSubLight // 标线的颜色
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
        },
        formatter: function (value) {
          return value >= 1000 ? value / 1000 + "k" : value;
        }
      }
    },
    series: [
      {
        type: "bar",
        data: curBarChartData,
        name: "Last week",
        itemStyle: {
          color: function (params) {
            return "#6ED2C8"; // 设置实心圆点的颜色为红色
          },
          borderRadius: [3, 3, 0, 0] // 上左、上右、下右、下左的圆角
        }
      },
      {
        name: "Previous week",
        ...WIDGET_CHART_COMMON_STYLE._seriesSymbolStyle,
        smooth: true,
        itemStyle: {
          normal: {
            color: "#FFA500", // 设置实心点的颜色
            borderColor: COLOR.main, // 设置边框颜色
            borderWidth: 2 // 设置边框宽度
          }
        },
        tooltip: {
          valueFormatter: function (value) {
            return value + " °C";
          }
        },
        data: prevLineChart
      }
    ]
  };
};
export const BarAndLineChartWithLightingOperateHoursWidgetOption = (curChart, prevChart) => {
  let category = [],
    curBarChartData = [],
    prevLineChart = [];

  curChart?.averageDay?.map(item => {
    category.push(moment(item.date).format("D/MM"));
    curBarChartData.push(item.value);
  });

  prevChart?.averageDay?.map(item => {
    prevLineChart.push(item.value);
  });
  return {
    legend: {
      show: true,
      data: [
        { name: "Last week", icon: "rect", itemStyle: { color: "#6ED2C8", borderColor: "transparent", fontSize: "0.78rem", fontFamily: "Inter" } },
        { name: "Previous week", icon: "rect", textStyle: { color: "#FFA500" }, itemStyle: { borderColor: "transparent" } }
      ],
      ...WIDGET_CHART_COMMON_STYLE["_legend"],
      itemGap: 5,
      top: 5,
      padding: [12, 5, 0, 0],
      formatter: function (name) {
        return "{a|" + name + "}";
      },
      textStyle: {
        ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle,
        letterSpacing: 10,
        rich: {
          a: {
            width: "auto" // 设置宽度自适应
          }
        }
      }
    },
    tooltip: {
      show: true,
      trigger: "axis",
      formatter: function (params) {
        let html = "";
        params.forEach(item => {
          html += `${item.marker}${item.seriesName}:<span class="bold">${ToDecimal(item.value)}Hrs</span><br/>`;
        });
        return html;
      }
    },
    xAxis: {
      type: "category",
      data: category,
      splitLine: {
        show: false
      },
      axisTick: {
        show: false // 显示 X 轴的刻度标线
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: COLOR.mainSubLight
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
        }
      }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._gridIncludeLegend,
      splitLine: {
        show: true,
        lineStyle: {
          type: WIDGET_CHART_STYLE._axisLineStyle.type
        }
      }
    },
    yAxis: {
      type: "value",
      name: "Hrs",
      nameTextStyle: { color: COLOR.mainSubLight, align: "right", fontSize: "0.78rem" },
      splitLine: {
        show: false,
        lineStyle: {
          width: 0.5, // 刻度标线的宽度
          color: "white" // 设置分隔线的颜色
        }
      },
      axisLine: {
        show: true, // 显示 Y 轴的标线
        lineStyle: {
          ...WIDGET_CHART_STYLE._axisLineStyle,
          color: COLOR.mainSubLight // 标线的颜色
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: COLOR.mainSubLight,
          fontSize: "0.78rem",
          fontFamily: "Inter"
        },
        formatter: function (value) {
          return value >= 1000 ? value / 1000 + "k" : value;
        }
      }
    },
    series: [
      {
        type: "bar",
        data: curBarChartData,
        name: "Last week",
        itemStyle: {
          color: function (params) {
            return "#6ED2C8"; // 设置实心圆点的颜色为红色
          },
          borderRadius: [3, 3, 0, 0] // 上左、上右、下右、下左的圆角
        }
      },
      {
        name: "Previous week",
        ...WIDGET_CHART_COMMON_STYLE._seriesSymbolStyle,
        smooth: true,
        itemStyle: {
          normal: {
            color: "#FFA500", // 设置实心点的颜色
            borderColor: COLOR.main, // 设置边框颜色
            borderWidth: 2 // 设置边框宽度
          }
        },
        tooltip: {
          valueFormatter: function (value) {
            return value + " °C";
          }
        },
        data: prevLineChart
      }
    ]
  };
};
export const BarAndLineChartWithComparsionWidgetOption = (category, data, compareData, periods, widget) => {
  let legend = [],
    tempSeries = [],
    prevLineSeries = [],
    unit = WIDGET_UNIT_BY_SOURCE_TYPE[widget.unit];
  const { default_reference_id } = JSON.parse(widget.parameters);
  let default_start_colors = ["#6ED2C8", "#A4D7E8"];
  if (default_reference_id) {
    default_start_colors = ["#A4D7E8", "#6ED2C8"];
  }
  const color = [...default_start_colors, ...WIDGET_CHART_COMMON_STYLE._barStyle.group_1.slice(2)];
  data?.map((item, index) => {
    legend.push({
      name: PAGE_PERIOD_LENGEND_TEXT[periods[0]],
      icon: "rect",
      textStyle: { color: color[index], fontSize: "12px", fontFamily: "Inter", padding: [2, 0, 0, 0] },
      itemStyle: { color: color[index] }
    });
    item.value = item?.value?.map(function (cItem) {
      return cItem?.value === EXPECTION_VALUE ? { name: cItem?.name, value: null } : { name: cItem?.name, value: cItem?.value };
    });
    tempSeries.push({
      type: "bar",
      data: item.value,
      name: PAGE_PERIOD_LENGEND_TEXT[periods[0]],
      itemStyle: {
        color: function () {
          return color[index]; // 设置实心圆点的颜色为红色
        },
        borderRadius: [3, 3, 0, 0] // 上左、上右、下右、下左的圆角
      }
    });
  });
  compareData?.map(item => {
    let name = "";
    if (default_reference_id) {
      name = item.name + " avg.";
    } else {
      name = PAGE_PERIOD_LENGEND_TEXT[periods[1]];
    }
    legend.push({
      name: name,
      icon: "rect",
      textStyle: { color: "#FFA500", fontSize: "0.78rem", fontFamily: "Inter", padding: [2, 0, 0, 0] },
      itemStyle: { color: "#FFA500", borderColor: "transparent" }
    });
    item.value = item?.value?.map(function (cItem) {
      return cItem?.value === EXPECTION_VALUE
        ? { name: default_reference_id ? name : cItem.name, value: null }
        : { name: default_reference_id ? name : cItem.name, value: cItem?.value };
    });
    prevLineSeries.push({
      name: name,
      ...WIDGET_CHART_COMMON_STYLE._seriesSymbolStyle,
      smooth: true,
      itemStyle: {
        normal: {
          color: "#FFA500", // 设置实心点的颜色
          borderColor: COLOR.main, // 设置边框颜色
          borderWidth: 2 // 设置边框宽度
        }
      },
      tooltip: {
        valueFormatter: function (value) {
          return value + unit;
        }
      },
      data: item.value
    });
  });
  return {
    legend: {
      show: true,
      data: legend,
      ...WIDGET_CHART_COMMON_STYLE["_legend"],
      itemGap: 5,
      top: 5,
      formatter: function (name) {
        return "{a|" + name + "}";
      },
      textStyle: {
        ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle,
        letterSpacing: 10,
        fontFamily: "Inter",
        rich: {
          a: {
            width: "auto" // 设置宽度自适应
          }
        }
      }
    },
    tooltip: {
      show: true,
      trigger: "axis",
      shared: true, // 多个数据点共享同一个 Tooltip
      formatter: function (item) {
        let html = "",
          nameObj = {};
        item.forEach(item => {
          if (default_reference_id) {
            item.seriesName = PAGE_PERIOD_LENGEND_TEXT[periods[0]];
          }
          nameObj = { ...nameObj, [item.name + "<br/>"]: item.name };
          html += `${item.marker}${item.seriesName}:<span class="bold">${UNIT_TO_COST_VALUE(widget?.unit, item.value, true)}</span><br/>`;
        });
        html = Object.keys(nameObj).join("") + html;
        return html;
      }
    },
    xAxis: {
      type: "category",
      data: category,
      splitLine: {
        show: false
      },
      axisTick: {
        show: false // 显示 X 轴的刻度标线
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: COLOR.mainSubLight
        }
      },
      axisLabel: {
        show: true,
        formatter: function (value) {
          return getTimeFormatByPelletOrViewby(widget, value);
        },
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
        }
      }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._gridIncludeLegend,
      splitLine: {
        show: true,
        lineStyle: {
          type: WIDGET_CHART_STYLE._axisLineStyle.type
        }
      }
    },
    yAxis: {
      type: "value",
      name: unit + UNIT_TO_SQUARE(widget?.unit),
      nameTextStyle: { color: COLOR.mainSubLight, fontSize: "0.78rem", align: widget?.unit.indexOf("EUI") != -1 ? "center" : "right" },
      splitLine: {
        show: false,
        lineStyle: {
          width: 0.5, // 刻度标线的宽度
          color: "white" // 设置分隔线的颜色
        }
      },
      axisLine: {
        show: true, // 显示 Y 轴的标线
        lineStyle: {
          // ...WIDGET_CHART_STYLE._axisLineStyle,
          color: COLOR.mainSubLight // 标线的颜色
        }
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: COLOR.mainSubLight,
          fontSize: "0.71rem",
          fontFamily: "Inter"
        },
        formatter: function (value) {
          return UNIT_COST_TO_YAXIS_VALUE(widget.unit, value);
        }
      }
    },
    series: [...tempSeries, ...prevLineSeries]
  };
};
function splitArrayIntoGroupsExcludingZero(array) {
  // 过滤出为0的元素并放入一个单独的数组
  const zeros = array.filter(item => item === 0);

  // 过滤掉为0的元素
  const nonZeroArray = array.filter(item => item !== 0);

  // 计算每组应有的元素数量（向上取整）
  const groupSize = Math.ceil(nonZeroArray.length / 15);

  // 分组非零元素
  const groups = [];
  for (let i = 0; i < nonZeroArray.length; i += groupSize) {
    const end = Math.min(i + groupSize, nonZeroArray.length);
    const group = nonZeroArray.slice(i, end);
    groups.push(group);
  }

  // 返回非零分组和零的数组
  return {
    groups: groups,
    zeros: zeros
  };
}
export const HeatMapWidgetOption = (categoryX, categoryY, list, type, widget) => {
  const data = list[0]?.data || [];
  const targetLength = categoryY.length;
  const week = [" S", " M", " T", " W", " T", " F", " S"];
  if (!data.length || !categoryX.length || !categoryY.length) {
    return {};
  }
  const color = WIDGET_CHART_COMMON_STYLE._barStyle.heatmap;
  let valueArr = [];
  data?.map(item => {
    valueArr.push(item[item.length - 1]);
  });
  valueArr.sort((a, b) => a - b);
  const groupedArray = splitArrayIntoGroupsExcludingZero(valueArr);
  const { groups, zeros } = groupedArray;
  groups.unshift(zeros);
  let pieces = [];
  for (let i = 0; i < groups.length; i++) {
    if (i == 0) {
      pieces.push({ min: 0, max: 0, color: color[i] });
    } else {
      pieces.push({ min: Math.min(...groups[i]), max: Math.max(...groups[i]), gte: Math.min(...groups[i]), lte: Math.max(...groups[i]), color: color[i] });
    }
  }
  //根据当前数组长度自动补齐数组
  while (categoryY.length < targetLength) {
    categoryY.push("");
  }
  return {
    tooltip: {
      formatter: function (params) {
        const _DATE = categoryY[params.value[1]].split("/");
        const dayOffWeek = new Date("20" + JSON.parse(JSON.stringify(_DATE)).reverse().join("-")).getDay();
        if (categoryY[params.value[1]].startsWith("more")) {
          return null;
        }
        let time,
          value = 0;
        if (params.value[0] <= 11) {
          time = params.value[0] + "\n" + "am";
        } else {
          time = (params.value[0] == 12 ? 12 : params.value[0] % 12) + "\n" + "pm";
        }
        if (params?.value?.[2]) {
          value = UNIT_TO_COST_VALUE(widget?.unit, params.value[2], true);
        }
        return `${week[dayOffWeek] || ""} ${categoryY[params.value[1]]}<br/>${params.marker}${time}：<span class="bold">${value}</span>`;
      }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._gridIncludeLegend,
      bottom: "1%",
      top: "5%",
      containLabel: true
    },
    xAxis: {
      type: "category",
      data: categoryX,
      axisTick: { show: false },
      axisLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        show: true,
        interval: 1,
        formatter: function (value) {
          if (value <= 11) {
            return value + "am";
          } else {
            return (value == 12 ? 12 : value % 12) + "pm";
          }
        },
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle,
          fontWeight: 300
        }
      }
    },
    yAxis: {
      type: "category",
      data: categoryY,
      interval: 0,
      axisTick: { show: false },
      axisLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        clickable: true, // 启用y轴label的点击事件
        show: true,
        formatter: function (date) {
          if (date && date != "more") {
            const _DATE = date.split("/");
            const dayOffWeek = new Date(JSON.parse(JSON.stringify(_DATE)).join("-")).getDay();
            return "{date|" + week[dayOffWeek] + parseInt(_DATE[2]) + "/" + parseInt(_DATE[1]) + "}";
          } else if (date && date == "more") {
            return date + "..";
            return "{date|" + date + ".." + "}";
          } else {
            return null;
          }
        },
        textStyle: {
          rich: {
            date: {
              color: COLOR.main,
              fontSize: "0.78rem",
              fontWeight: "300",
              fontFamily: "Inter",
              padding: [0, 2, 0, 0]
            }
          }
        }
      },
      triggerEvent: true
    },
    visualMap: {
      //需要可展开
      show: false,
      type: "piecewise",
      min: valueArr?.length ? Math.min(...valueArr) : 0, // 数据值的最小值
      max: valueArr?.length ? Math.max(...valueArr) : 0, // 数据值的最大值
      calculable: true, // 是否显示拖拽用的手柄
      pieces: pieces
    },
    series: [
      {
        name: "HeatMap",
        type: "heatmap",
        data: data,
        splitArea: {
          show: false
        },
        label: {
          show: false
        },
        pointSize: 14,
        symbolSize: [13.75, 24.14], // 设置每个点的宽高
        itemStyle: {
          borderColor: COLOR.white,
          borderWidth: 2,
          borderRadius: 0
        },
        axisLabel: {
          interval: 3 // 设置刻度标签显示间隔
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 2,
            shadowColor: "#4d648d"
          }
        }
      }
    ]
  };
};
export const BarChartWaterBreakDownOption = (categoryX, categoryY, list, type) => {
  if (!list[0]?.data?.length) {
    return {};
  }
  let color = null;
  if (type == "WET_WATER") {
    color = WIDGET_CHART_COMMON_STYLE._barStyle.group_1;
  } else {
    color = WIDGET_CHART_COMMON_STYLE._barStyle.breakDownEnergy;
  }
  let legendData = [];
  let seriesData = [];
  categoryX.map((item, index) => {
    legendData.push({
      name: item,
      icon: "rect",
      textStyle: {
        color: color[index],
        fontSize: WIDGET_CHART_STYLE._axis.fontSize
      }
    });
  });
  let max = [];
  list[0]?.data.map((item, index) => {
    let currentData = [];
    item.data.forEach((item, index) => {
      currentData.push(item);
      if (max[index]) {
        max[index] += parseFloat(currentData[index]);
      } else {
        max[index] = parseFloat(currentData[index]);
      }
    });
    seriesData.push({
      name: item.name,
      type: "bar",
      stack: "Tik Tok",
      itemStyle: {
        color: color[index],
        shadowColor: color[index],
        shadowBlur: 0,
        shadowOffsetY: 0,
        shadowOffsetX: 0,
        borderWidth: 1
      },
      label: {
        normal: {
          show: true,
          position: "inside",
          formatter: function (params) {
            if (params.value < 10) {
              return "";
            }
            return params.value + "%";
          },
          textStyle: {
            align: "center",
            baseline: "middle",
            fontSize: "0.78rem",
            fontWeight: "400",
            color: COLOR.white,
            fontFamily: "Inter"
          }
        }
      },
      data: currentData
    });
  });
  return {
    legend: {
      show: true,
      data: [...legendData],
      ...WIDGET_CHART_COMMON_STYLE["_legend"],
      itemGap: 5,
      left: 5,
      bottom: 1,
      formatter: function (name) {
        return "{a|" + name + "}";
      },
      textStyle: {
        ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle,
        letterSpacing: 10,
        rich: {
          a: {
            width: "auto" // 设置宽度自适应
          }
        }
      }
    },
    tooltip: {
      formatter: function (params) {
        if (categoryX.includes(params.seriesName)) {
          return `${params.marker}${params.seriesName}：${params.value}%`;
        } else {
          return null;
        }
      }
    },
    grid: {
      ...WIDGET_CHART_COMMON_STYLE._grid,
      left: "-1%",
      right: "auto",
      top: list[0].data.length == 1 ? "0%" : list[0].data.length > 5 ? "-10%" : "-10%",
      height: list[0].data.length == 1 ? "130%" : list[0].data.length > 5 ? "120%" : "140%"
    },
    xAxis: [
      {
        show: false
      },
      {
        show: false
      }
    ],
    yAxis: {
      type: "category",
      inverse: true,
      show: false
    },
    series: [
      {
        name: "",
        type: "bar",
        xAxisIndex: 0, //使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
        yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
        data: [...max, 100],
        barWidth: 5,
        itemStyle: {
          normal: {
            color: "transparent"
          }
        },
        z: 1
      },
      ...seriesData,
      {
        show: false,
        type: "bar",
        xAxisIndex: 1, //代表使用第二个X轴刻度
        barGap: "-100%",
        barWidth: "10%",
        itemStyle: {
          normal: {
            barBorderRadius: 200,
            color: "transparent"
          }
        },
        label: {
          normal: {
            show: true,
            position: "leftTop",
            padding: [-20, 0, 0, 0],
            textStyle: {
              fontSize: "0.78rem",
              fontFamily: "Inter",
              color: COLOR.main,
              fontWeight: "bold"
            },
            formatter: function (data) {
              return categoryY[data.dataIndex];
            }
          }
        },
        data: max
      }
    ]
  };
};
export const BarChartEnergyBreakDownOption = (category = "", list = [], type) => {
  if (!list?.length) {
    return {};
  }
  let obj_reconstitution = {};
  list.map(pItem => {
    pItem.data?.map(cItem => {
      if (obj_reconstitution[cItem.name]) {
        obj_reconstitution[cItem.name] = {
          name: cItem.name,
          data: [...obj_reconstitution[cItem.name].data, cItem.value]
        };
      } else {
        obj_reconstitution[cItem.name] = {
          name: cItem.name,
          data: [cItem.value]
        };
      }
    });
  });
  const data_reconstitution = Object.keys(obj_reconstitution).map(key => obj_reconstitution[key]);
  const categoryX = Object.keys(obj_reconstitution);
  const categoryY = category;
  return BarChartWaterBreakDownOption(categoryX, categoryY, [{ data: data_reconstitution }], type);
};
function setAllFridgeStyleObj(points, color) {
  let fridgetObj = {};
  for (let index in points) {
    index = parseInt(index) + 1;
    fridgetObj = {
      ...fridgetObj,
      ["fridge" + index]: {
        width: "auto",
        color: COLOR.white,
        backgroundColor: color[index - 1],
        borderRadius: 2,
        padding: [2, 5, 2, 5],
        fontFamily: "Inter"
      }
    };
  }
  return fridgetObj;
}
function maxLength(twoDArray) {
  return twoDArray.reduce((max, current) => {
    return Math.max(max, current.length);
  }, 0);
}
export const ScatterChartTemperatureDistributionOption = (categoryX, data, pointsFridge, width, default_set_point) => {
  if (data?.length === 0) {
    return {};
  }
  let color = WIDGET_CHART_COMMON_STYLE._barStyle.temperature;
  let tempMax = null,
    tempMin = null,
    seriesData = []; // 计算中间值
  let regroupObj = {};
  let max = maxLength(data);
  data = data.map(item => {
    if (item?.length < max) {
      for (let i = 0; i < max; i++) {
        item.push({});
      }
    }
    return item;
  });
  data.map(item => {
    item?.map((cItem, index) => {
      if (regroupObj[index] && item[index]) {
        if (!tempMax) tempMax = cItem.value;
        if (!tempMin) tempMin = cItem.value;
        if (cItem.value > tempMax) {
          tempMax = cItem.value;
        }
        if (cItem.value < tempMin) {
          if (cItem.value) {
            tempMin = cItem.value;
          }
        }
        regroupObj[index].push({ name: index + 1, value: cItem?.value, fridgeName: cItem.name });
      } else {
        if (!tempMax) tempMax = cItem.value;
        if (!tempMin) tempMin = cItem.value;
        if (cItem?.value > tempMax) {
          tempMax = cItem.value;
        }
        if (cItem?.value < tempMin) {
          if (cItem.value) {
            tempMin = cItem.value;
          }
        }
        regroupObj[index] = [{ name: index + 1, value: cItem?.value, fridgeName: cItem.name }];
      }
    });
  });
  default_set_point = Math.abs(default_set_point);
  let numbers = [],
    markLine = [];
  if (tempMax && tempMin) {
    tempMax = Math.round(tempMax || 0);
    tempMin = Math.round(tempMin || 0);
    if (tempMin / tempMax > 0.5) {
      numbers = [Math.round(tempMax || 0), Math.round(tempMax / 2), 0];
    } else {
      numbers = [Math.round(tempMax || 0), Math.round((tempMax + tempMin) / 2), Math.round(tempMin || 0)];
    }
    if (tempMin < default_set_point) {
      numbers.push(default_set_point);
    }
    numbers.sort((a, b) => b - a);
  }
  for (let i = 1; i <= numbers.length; i++) {
    let yAxis = numbers[i - 1];
    let color = COLOR.mainSubLight,
      borderWidth = 1;
    if (yAxis == default_set_point) {
      color = COLOR.yellow;
      borderWidth = 2;
    }
    markLine.push({
      yAxis: yAxis,
      symbolSize: 0.1,
      lineStyle: { width: borderWidth, color: color },
      label: {
        show: true,
        color: COLOR.mainSubLight,
        backgroundColor: COLOR.white,
        fontSize: "0.8rem",
        fontFamily: "Inter",
        formatter: yAxis == default_set_point ? "" : yAxis + " ℃"
      }
    });
  }
  for (let item in regroupObj) {
    seriesData.push({
      name: "Fridge at each store",
      type: "scatter",
      symbol: "rect",
      symbolSize: 16,
      smooth: true,
      zlevel: 3,
      animationDuration: 2000,
      data: regroupObj[item],
      label: {
        // 设置标签样式
        show: true, // 显示标签
        formatter: function (params) {
          return "F" + params.data.name;
        },
        color: COLOR.white,
        fontSize: "0.57rem",
        fontFamily: "Inter",
        position: "inside" // 标签位于顶部
      },
      itemStyle: {
        color: function (params) {
          return color[params.seriesIndex];
        },
        borderWidth: 1,
        borderColor: "transparent",
        borderRadius: 0 // 设置圆角大小
      },
      markLine: {
        symbol: "none",
        silent: true,
        lineStyle: { normal: { type: "solid" } },
        label: {
          position: "start",
          color: "white",
          fontFamily: "Inter"
        },
        data: markLine
      }
    });
  }
  return {
    lineData: Array.from(new Set(numbers)),
    option: {
      tooltip: {
        zIndex: 2,
        trigger: "axis",
        confine: true,
        formatter: function (params) {
          let html = categoryX[params[0]?.["dataIndex"]] + "<br/>";
          params?.forEach(item => {
            if (item?.data?.value || item?.data?.value == 0) {
              html += `${item.marker}${item?.data?.fridgeName}：${ToRoundDecimalTemperature(item?.data?.value)}℃<br/>`;
            }
          });
          return html;
        }
      },
      legend: {
        show: false,
        itemWidth: 0,
        itemHeight: 0,
        data: [{ name: "Fridge at each store", icon: "rect", itemStyle: { color: COLOR.white } }],
        type: "plain",
        right: "5",
        formatter: function (params) {
          let str = "";
          for (let item in regroupObj) {
            str += "  ";
            str += `{fridge${parseInt(item) + 1}|${parseInt(item) + 1}}`;
          }
          let title = "Fridge at each store";
          return "{title|" + title + "}" + str;
        },
        textStyle: {
          ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle,
          rich: {
            title: {
              width: "auto", // 设置宽度自适应
              fontSize: "13px"
            },
            ...setAllFridgeStyleObj(regroupObj, color)
          }
        }
      },
      grid: {
        width: "auto",
        top: "8%",
        left: "10%",
        right: "0%",
        bottom: "0%",
        height: "92%",
        containLabel: true
      },
      title: {
        text: "",
        left: "center"
      },
      xAxis: {
        type: "category",
        splitLine: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: true,
          interval: 0,
          textStyle: {
            ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle
          },
          formatter: function (value) {
            if (width <= 6) {
              let str = "";
              let strArr = value.split(" ");
              for (let item in strArr) {
                str += strArr[item] + "\n";
              }
              return str;
            } else {
              return value;
            }
          }
        },
        data: categoryX
      },

      yAxis: {
        type: "value",
        splitLine: {
          show: false,
          lineStyle: {
            color: "transparent"
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false,
          lineStyle: {
            width: 3
          }
        },
        axisLabel: {
          show: false,
          formatter: function (value) {
            return value;
          },
          rich: {
            val: {
              width: "auto" // 设置宽度自适应
            }
          },
          textStyle: {
            ...WIDGET_CHART_COMMON_STYLE._dashboard_textStyle,
            color: "transparent"
          }
        }
      },
      series: [...seriesData]
    }
  };
};
export const getDataFieldByVisualType = visualType => {
  switch (visualType) {
    case "HeatMapWidget":
      return "dataHeatMap";
    case "LineChartWidget":
      return "dataLineChart";
    case "BarChartWithMeterConsumptionCompareWidget":
      return {
        field: "dataBarChartCompare",
        series: {},
        xAxis: {
          axisLine: { show: false }
        }
      };
    case "BarChartWithBDSTWidget":
      return {
        field: "dataBarChartBdst",
        series: {},
        xAxis: {
          axisLine: { show: false }
        }
      };
    case "BarChartWithMeterUsageTypeWidget":
      return "dataBarChartUsage";
    case "BarChartWithMeterConsumptionPercentDifferWidget":
      return "dataBarChartPercent";
    case "BarChartWaterBreakDownWidget":
      return {
        field: "dataBarChartWaterBreakDown"
      };
    case "BarChartEnergyBreakDownWidget":
      return {
        field: "dataBarChartEnergyBreakDown"
      };
  }
};
export const getTogglByPellet = pellet => {
  switch (pellet) {
    case "PELLET_HOUR":
      return "hour";
      break;
    case "PELLET_DAY":
      return "day";
      break;
    case "PELLET_MONTH":
      return "month";
      break;
  }
};
export const getTimeFormatByPelletOrViewby = (widget, value, isTooltipDate, dateFormat) => {
  let viewBy,
    monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const { default_period } = widget?.parameters ? JSON.parse(widget.parameters) : {};
  if (widget?.params?.period || default_period) {
    viewBy = getTogglByPellet(TimestampPeriodConversion(widget.params.period || default_period).pellet);
  } else {
    let parameters = JSON.parse(widget.parameters);
    viewBy = parameters.view_by;
  }
  if (viewBy == "hour") {
    let params = null;
    if (isTooltipDate) {
      if (dateFormat) {
        params = moment(value * 1000).format(dateFormat);
        return params;
      } else {
        params = moment(value * 1000)
          .format("YYYY/MM/DD h:a")
          .split(":");
        return params[0] + "" + params[1] + "<br/>";
      }
    } else {
      params = moment(value * 1000)
        .format("h:a")
        .split(":");
    }

    return params[0] + "" + params[1];
  } else {
    if (viewBy == "day") {
      if (dateFormat) {
        return moment(value * 1000).format(dateFormat);
      } else {
        if (isTooltipDate) {
          return moment(value * 1000).format("D/M") + " ";
        }
        return moment(value * 1000).format("D/M");
      }
    } else {
      if (dateFormat) {
        return moment(value * 1000).format(dateFormat);
      } else {
        return monthsShort[moment(value * 1000).format("M") - 1];
      }
    }
  }
};
export const TimestampPeriodConversion = period => {
  let startTime, endTime, pellet;
  moment.utc();
  switch (period) {
    case "WDP_TODAY":
      startTime = moment().startOf("day").unix();
      endTime = moment().endOf("day").unix();
      pellet = "PELLET_HOUR";
      break;
    case "WDP_YESTERDAY":
      startTime = moment().subtract(1, "days").startOf("day").unix();
      endTime = moment().subtract(1, "days").endOf("day").unix();
      pellet = "PELLET_HOUR";
      break;
    case "WDP_THISWEEK":
      startTime = moment().startOf("week").unix();
      endTime = moment().endOf("week").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_LASTWEEK":
      startTime = moment().startOf("week").subtract(1, "week").unix();
      endTime = moment().endOf("week").subtract(1, "week").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_LAST2WEEK":
      startTime = moment().subtract(2, "weeks").startOf("week").unix();
      endTime = moment().subtract(1, "weeks").endOf("week").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_THISMONTH":
      startTime = moment().startOf("month").unix();
      endTime = moment().endOf("month").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_LASTMONTH":
      startTime = moment().subtract(1, "months").startOf("month").unix();
      endTime = moment().subtract(1, "months").endOf("month").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_THISQUARTER":
      startTime = moment().startOf("quarter").unix();
      endTime = moment().endOf("quarter").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_LASTQUARTER":
      startTime = moment().subtract(1, "quarters").startOf("quarter").unix();
      endTime = moment().subtract(1, "quarters").endOf("quarter").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_THISYEAR":
      startTime = moment().startOf("year").unix();
      endTime = moment().endOf("year").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_LASTYEAR":
      startTime = moment().startOf("year").subtract(1, "year").unix();
      endTime = moment().endOf("year").subtract(1, "year").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_MONTHTODATE":
      startTime = moment().startOf("month").unix();
      endTime = moment().unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_QUARTERTODATE":
      startTime = moment().startOf("quarters").unix();
      endTime = moment().unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_YEARTODATE":
      startTime = moment().startOf("year").unix();
      endTime = moment().unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_PAST7DAYS":
      startTime = moment().subtract(7, "days").startOf("day").unix();
      endTime = moment().subtract(1, "day").endOf("day").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_PAST2WEEKS":
      startTime = moment().subtract(14, "days").startOf("day").unix();
      endTime = moment().subtract(1, "day").endOf("day").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_PASTMONTH":
      var yesterday = moment().subtract(1, "days");
      var threeMonthsAgo = yesterday.subtract(1, "months");
      startTime = moment(threeMonthsAgo).unix();
      endTime = moment().subtract(1, "days").unix();
      pellet = "PELLET_DAY";
      break;
    case "WDP_PAST3MONTH":
      yesterday = moment().subtract(1, "days");
      threeMonthsAgo = yesterday.subtract(3, "months");
      startTime = moment(threeMonthsAgo).unix();
      endTime = moment().subtract(1, "days").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_PAST6MONTH":
      yesterday = moment().subtract(1, "days");
      threeMonthsAgo = yesterday.subtract(6, "months");
      startTime = moment(threeMonthsAgo).unix();
      endTime = moment().subtract(1, "days").unix();
      pellet = "PELLET_MONTH";
      break;
    case "WDP_PAST12MONTH":
      yesterday = moment().subtract(1, "days");
      threeMonthsAgo = yesterday.subtract(12, "months");
      startTime = moment(threeMonthsAgo).unix();
      endTime = moment().subtract(1, "days").unix();
      pellet = "PELLET_MONTH";
      break;
  }
  return { startTime, endTime, pellet };
};
// layout reArrangement
export const layoutRearrangement = widgets => {
  let defaultX = 0;
  widgets &&
    widgets.map(item => {
      item.layout.x = defaultX;
      defaultX += item.layout.w;
      if (defaultX >= 6) {
        defaultX = 0;
      }
      return item;
    });
  return widgets;
};
